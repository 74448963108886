export function getAdminDocuments(options, filters, token) {
  let parameters = mapToParameters(options);
  let body = mapToBody(filters);
  let bodyString = JSON.stringify(body);
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'POST'
  };

  requestOptions.body = bodyString;
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  let queryString = new URLSearchParams(parameters).toString();

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/document/list/admin?" + queryString, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function getAdminUsers(filter, token) {
  let parameters =  {};
  if (filter != null) {
    parameters['filter'] = filter;
  }

  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'GET'
  };

  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  let queryString = new URLSearchParams(parameters).toString();

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/companies/list?" + queryString, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}


export function getUserStatistics(userId, token) {
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'GET'
  };

  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/companies/userStatistics/" + userId, requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function resendDocumentToSef(docId, token) {
  let body = [ docId ];
  let bodyString = JSON.stringify(body);
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'POST'
  };

  requestOptions.body = bodyString;
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/admin/resendIds", requestOptions)
  .then(response => {
    if (response.ok) {
      return response;
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function resendDocumentToAmaps(docId, token) {
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'POST'
  };

  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/admin/resendToAmaps/" + docId, requestOptions)
  .then(response => {
    if (response.ok) {
      return response;
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function getAsPekareOutDocsStatuses(token) {
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'GET'
  };

  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/report/asPekare/outStatuses", requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function getAsPekareInDocsStatuses(token) {
  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'GET'
  };

  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  requestOptions.headers = requestHeaders;

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/report/asPekare/inStatuses", requestOptions)
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    else {
      if (response.status === 400) {
        return response.json()
          .then(jsonData => {
            if (jsonData.ErrorMessage)
              throw new Error(jsonData.ErrorMessage);
            else
              throw new Error(jsonData.ErrorCode);
          });
      }
      else {
        throw response;
      }
    }
  })
}

export function loginToEdex() {
  let body = {
    'username': 'antonijep39@gmail.com',
    'password': 'novaLozinkaOd12'
  };

  let bodyString = JSON.stringify(body);

  const requestHeaders = new Headers();
  const requestOptions = {
    method: 'POST'
  };

  requestOptions.body = bodyString;
  requestHeaders.set('Content-Type', 'application/json');
  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(process.env.VUE_APP_EDEX_BASEURL + "/auth/login", requestOptions)
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw response;
      }
    })
}

export function mapToBody(filters) {
  let parameters = {};
  parameters.isSentToSef = filters.isSent;
  parameters.sefStatusMessage = filters.sefStatusMessage;
  parameters.documentType = filters.documentType;
  parameters.documentNumber = filters.documentNumber;
  parameters.partnerCompany = filters.partnerCompany;
  parameters.actualDeliveryDateFrom = filters.actualDeliveryDateFrom;
  parameters.actualDeliveryDateTo = filters.actualDeliveryDateTo;
  parameters.senderCompany = filters.senderCompany;
  parameters.receiverCompany = filters.receiverCompany;

  return parameters;
}

export function mapToParameters(options) {
  let parameters = {};
  if (options.page !== null) {
    parameters.page = options.page - 1;
  }

  if (options.itemsPerPage !== null) {
    parameters.size = options.itemsPerPage;
  }

  return parameters
}
