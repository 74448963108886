<template>
  <v-container class="mt-14">
    <as-pekare-statuses :token="edexToken" />
  </v-container>
</template>

<script>
import * as apiSefLink from "../api/seflink.js";
import AsPekareStatuses from '../components/AsPekareStatuses.vue';

export default {
  name: "AsPekareStatusesPage",
  components: {
    AsPekareStatuses
  },
  data: function () {
    return {
      loading: false,
      finishedLoading: false,
      edexToken: null,
    }
  },
  mounted () {
    apiSefLink.loginToEdex()
      .then(response => response.json())
      .then(responseData => {
        this.edexToken = responseData.token;
      });
  },
}
</script>
