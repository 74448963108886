<template>
  <v-row>
    <v-col cols="12">
      <v-subheader>As Pekare - Statusi </v-subheader>
    </v-col>
    <v-col>
      <v-card width="100%">
        <v-card-title>
          Izlazni dokumenti
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="outDocumentsStatuses"
          :loading="outDocsLoading || token === null"
          :server-items-length="outDocumentsStatusesCount"
          no-data-text="Nema rezultata"
          loading-text="Učitavanje podataka... Molimo sačekajte"
          class="elevation-1 row-pointer"
          width="100%"
        >
          <template v-slot:[`item.count`]="{ item }">
            {{ item.count.toLocaleString() }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col>
      <v-card width="100%">
        <v-card-title>
          Ulazni dokumenti
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="inDocumentsStatuses"
            :server-items-length="inDocumentsStatusesCount"
            :loading="inDocsLoading  || token === null"
            no-data-text="Nema rezultata"
            loading-text="Učitavanje podataka... Molimo sačekajte"
            class="elevation-1 row-pointer"
            width="100%"
          >
            <template v-slot:[`item.count`]="{ item }">
              {{ item.count.toLocaleString() }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as seflinkApi from '../api/seflink.js';

export default {
  name: 'AsPekareStatuses',
  data: function () {
    return {
      outDocsLoading: false,
      inDocsLoading: false,
      outDocumentsStatuses: [],
      outDocumentsStatusesCount: 0,
      inDocumentsStatuses: [],
      inDocumentsStatusesCount: 0,
      headers: [
          { text: 'Status ID', value: 'status' },
          { text: 'Status', value: 'statusName' },
          { text: 'Broj', value: 'count' },
      ]
    }
  },
  props: {
    token: {
      type: String,
      required: false,
      default: null
    }
  },
  watch: {
    token: {
      handler() {
        this.getOutStatusesFromAPI();
        this.getInStatusesFromAPI();
      }
    }
  },
  methods: {
    getOutStatusesFromAPI() {
      this.outDocsLoading = true;

      if (this.token === null) {
        return;
      }

      seflinkApi.getAsPekareOutDocsStatuses(this.token)
        .then(responseData => {
          console.log(responseData);
          this.outDocumentsStatuses = responseData;
          this.outDocumentsStatusesCount = responseData.length;
          this.outDocsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.outDocumentsStatuses = []
          this.outDocsLoading = false
        })
    },
    getInStatusesFromAPI() {
      this.inDocsLoading = true;

      if (this.token === null) {
        return;
      }

      seflinkApi.getAsPekareInDocsStatuses(this.token)
        .then(responseData => {
          console.log(responseData);
          this.inDocumentsStatuses = responseData;
          this.inDocumentsStatusesCount = responseData.length;
          this.inDocsLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.inDocumentsStatuses = []
          this.inDocsLoading = false
        })
    },
  }
}
</script>
